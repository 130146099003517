<template>
  <ModernModalLayout
    title="Выберите способ подписи документов"
    :persistent="disabledForms"
    :close-disabled="disabledForms"
  >
    <div>
      <Spinner v-if="loading" />
      <div
        v-else-if="accountCertificates.length === 0"
        class="text-center my-3"
      >
        Сертификаты отсутствуют.
      </div>
      <template v-else>
        <CryptoProTokens
          v-model="token"
          :account-certificates="accountCertificates"
          :disabled="disabledForms"
        />
        <template v-if="!SIGNME_DISABLE">
          <SignMeTokens
            v-model="token"
            :account-certificates="accountCertificates"
            :disabled="disabledForms"
          />
        </template>
      </template>
    </div>

    <template #actions:append>
      <v-btn
        elevation="0"
        color="primary"
        :loading="$wait('onSubmit')"
        :disabled="disabledSubmit"
        @click="onSubmit"
      >
        Выбрать
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import env from '@/plugins/env';
import waitable from '@/utils/mixins/waitable';
import CryptoProTokens from '@/components/CryptoProTokens';
import SignMeTokens from '@/components/SignMeTokens';
import Spinner from '@/components/Spinner';
import ModernModalLayout from '@/components/layouts/ModernModalLayout';

export default {
  components: {
    CryptoProTokens,
    SignMeTokens,
    Spinner,
    ModernModalLayout,
  },

  mixins: [waitable],

  props: {
    submit: {
      type: Function,
      default: null,
    },
  },

  data: () => ({
    token: null,
    loading: false,
  }),

  computed: {
    ...mapGetters('AUTH', ['currentAccount']),
    ...mapGetters('TOKENS', [
      'activeToken',
      'accountCertificates',
      'cryptoProTokensInitialized',
    ]),

    SIGNME_DISABLE() {
      return env.get('VUE_APP_SIGNME_DISABLE');
    },

    disabledSubmit() {
      return (
        !(this.cryptoProTokensInitialized && !this.cryptoProTokensError) ||
        !this.token ||
        this.$wait('onSubmit')
      );
    },

    disabledForms() {
      return this.$wait('login') || this.$wait('onSubmit');
    },
  },

  watch: {
    activeToken(val) {
      if (val) this.token = this.activeToken;
    },
  },

  async created() {
    this.loading = true;
    await this.$store.dispatch('TOKENS/fetchAccountCertificates');
    this.loading = false;
  },

  async mounted() {
    if (this.activeToken) this.token = this.activeToken;
  },

  methods: {
    async onSubmit() {
      await this.$loading(
        this.$store.dispatch('TOKENS/changeActiveToken', {
          type: this.token.type,
          token: this.token,
        }),
        'onSubmit',
      );

      this.$emit('close');
    },
  },
};
</script>
